<template>
  <div class="authorPage flex-column-center">
    <div
      class="userLine"
      v-if="userInfo"
    >
      <img
        class="userIcon"
        :src="userInfo.avatar"
        alt=""
      >
      <div class="userName">{{userInfo.nickname}}</div>
      <div class="userTitle">{{userInfo.official}}</div>
      <!-- 关注按钮 -->
      <div
        class="followBtn flex-row-center"
        v-show="!followStatus && userId!==localUserId"
        @click="goFollow(userInfo.id)"
      >
        <img
          class="followIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/followIcon.png"
          alt=""
        >
        <div class="followText">關注</div>
      </div>
      <!-- 取消关注按钮 -->
      <div
        class="unFollowBtn"
        v-show="followStatus && userId!==localUserId"
        @click="goUnFollow(userInfo.id)"
      >已關注</div>
    </div>
    <div
      class="numLine flex-row-center"
      v-if="userInfo"
    >
      <div
        class="numBox flex-column-center"
        @click="goPage(0)"
      >
        <div class="num">{{userInfo.fan_cnt}}</div>
        <img
          class="tag"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/fansTag.png"
          alt=""
        >
      </div>
      <div class="divider"></div>
      <div
        class="numBox flex-column-center"
        @click="goPage(1)"
      >
        <div class="num">{{userInfo.follow_cnt}}</div>
        <img
          class="tag"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/focusTag.png"
          alt=""
        >
      </div>
    </div>
    <div class="postList">
      <img
        class="postListTag"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/postListTag.png"
        alt=""
      >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="initUserPost"
      >
        <PostItem
          :iconIndex="0"
          v-for="(item,index) in postList"
          :key="index"
          :itemData="item"
        ></PostItem>
      </van-list>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import PostItem from '../../components/postItem/PostItem.vue'
import { getUserPost, getUserInfo } from '../../api/service'
import { unFollowUser, followUser } from '../../api/service'

export default {
  name: 'Author',
  components: {
    PostItem
  },
  data() {
    return {
      followStatus: false,
      userId: null,
      loading: false,
      finished: false,
      postList: [],
      cursor: '',
      userInfo: null,
      localUserId: null
    }
  },
  mounted() {
    this.userId = this.$route.query.userId
    this.localUserId = localStorage.userId
    this.initUserInfo()
  },
  methods: {
    initUserInfo() {
      getUserInfo(this.userId).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data
          this.followStatus = res.data.followed
        }
      })
    },
    initUserPost() {
      getUserPost(this.userId, {
        cursor: this.cursor,
        page_size: 5
      }).then((res) => {
        if (res.code == 0) {
          this.cursor = res.data.cursor
          res.data.list.map((item) => {
            this.postList.push(item)
          })
          this.loading = false
          if (!res.data.cursor) {
            this.finished = true
          }
        }
      })
    },
    goPage(index) {
      switch (index) {
        case 0:
          this.$router.push({
            path: 'Fans',
            query: {
              fansNum: this.userInfo.fan_cnt,
              userId: this.userId
            }
          })
          break
        case 1:
          this.$router.push({
            path: 'Focus',
            query: {
              focusNum: this.userInfo.follow_cnt,
              userId: this.userId
            }
          })
          break

        default:
          break
      }
    },
    goUnFollow(id) {
      unFollowUser(id).then((res) => {
        if (res.data.code == 0) {
          this.followStatus = false
        }
      })
    },
    goFollow(id) {
      followUser(id).then((res) => {
        if (res.code == 0) {
          this.followStatus = true
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Author.scss';
</style>