<template>
  <div
    class="postItemBox flex-column-center"
    @click="goDetail"
  >
    <div class="userLine">
      <img
        class="userIcon"
        :src="itemData.user.avatar"
        alt=""
        @click.stop="goUserInfo"
      >
      <div class="userName">{{itemData.user.nickname}}</div>
      <div class="userTitle">{{itemData.user.official}}</div>
      <div class="postTime">{{getTime(itemData.create_time)}}</div>
    </div>
    <div class="postDetail flex-column-center">
      <div class="titleLine flex-row-center">
       
        <div v-show="itemData.is_event" class="titleIcon1"></div>
        <div v-show="itemData.is_selected" class="titleIcon2"></div>
       
        <span
          v-show="itemData.is_selected || itemData.is_event"
          class="titleTextShort"
        >{{itemData.title}}</span>
        <span
          v-show="!itemData.is_event && !itemData.is_selected"
          class="titleTextLong"
        >{{itemData.title}}</span>
      </div>
      <div class="contentLine">{{itemData.content}}</div>
      <div
        class="imgLine flex-row-center"
        v-if="itemData.images"
      >
        <div
          v-for="(item,index) in itemData.images"
          :key="index"
        >
          <img
            v-if="index<3"
            class="postImg"
            :src="item"
            alt=""
          >
        </div>
        <div
          v-if="itemData.images.length>3"
          class="totalNum"
        >共{{itemData.images.length}}张</div>
      </div>
      <div class="actionLine flex-row-center">
        <div class="actionItem flex-row-center">
          <img
            class="actionIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/shareIcon.png"
            alt=""
          >
          <div class="actionNum">{{itemData.shared_cnt}}</div>
        </div>
        <div class="actionItem flex-row-center">
          <img
            class="actionIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentIcon.png"
            alt=""
          >
          <div class="actionNum">{{itemData.replied_cnt}}</div>
        </div>
        <div class="actionItem flex-row-center">
          <img
            class="actionIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon.png"
            alt=""
            @click.stop="zan"
            v-show="!itemData.liked"
          >
          <img
            class="actionIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/redZanIcon.png"
            alt=""
            @click.stop="zan"
            v-show="itemData.liked || itemData.liked"
          >
          <div class="actionNum">{{itemData.liked_cnt}}</div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</template>
<script type="text/ecmascript-6">
import { getFormateTime } from '../../util/timeUtil'
import { likePost, cancelLikePost } from '../../api/service'
import { Toast } from 'vant'

export default {
  name: 'PostItem',
  props: {
    type: {},
    itemData: {
      type: Object
    }
  },
  computed: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    zan() {
      if (!this.itemData.liked) {
        this.goLikePost()
      } else {
        this.goDisLikePost()
      }
    },
    goDetail() {
      this.$router.push({
        path: 'postDetail',
        query: { postId: this.itemData.id }
      })
    },
    getTime(time) {
      return getFormateTime(parseInt(time))
    },
    goLikePost() {
      likePost(this.itemData.id).then((res) => {
        if (res.code == 0) {
          this.itemData.liked = true
          this.itemData.liked_cnt++
          Toast('點贊成功')
        }
      })
    },
    goDisLikePost() {
      cancelLikePost(this.itemData.id).then((res) => {
        if (res.data.code == 0) {
          this.itemData.liked = false
          this.itemData.liked_cnt--
          Toast('取消成功')
        }
      })
    },
    goUserInfo() {
      this.$router.push({
        path: 'Author',
        query: {
          userId: this.itemData.user.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './PostItem.scss';
</style>